//let apiUrl = 'https://stageapi.kanam.io/'
let apiUrl = 'https://api.kanam.io/'


const environment = {
  api: apiUrl,
  secretKey:"kanam2023",
  tinymceApiKey: 'gdoj7fdjyve5ad4z25rnq08y8jvlz2nnvv81xpgh58smh5vr',
};

// backend url=http://portal.jcsoftwaresolution.in:6062/
export default environment;
